<template>
  <v-app>
    <v-main fluid tag="section" class="pt-0 overflow-hidden">
      <TopBar
        title="Action Items"
        @show-notifications="onShowNotifications"
        ref="topBar"
      />

      <v-navigation-drawer
        :app="!$vuetify.breakpoint.xsOnly"
        v-model="showNotificationPane"
        right
        :permanent="showNotificationPane"
        hide-overlay
        width="375px"
        stateless
        :absolute="$vuetify.breakpoint.xsOnly"
        :style="{
          'z-index': 10,
          'background-color': '#fafafa',
        }"
      >
        <NotificationsPane
          v-if="showNotificationPane"
          @close-notification-pane="showNotificationPane = false"
        />
      </v-navigation-drawer>

      <v-dialog
        v-model="showEditFormDialog"
        max-width="600px"
        persistent
        :fullscreen="isFullScreen"
      >
        <v-card>
          <DynamicForm
            :formDefinition="dynamicFormProps.formDefinition"
            :existingFormResultIdMap="dynamicFormProps.existingFormResultIdMap"
            :selectedPdfFileUrl="dynamicFormProps.selectedPdfFileUrl"
            :canEdit="dynamicFormProps.canEdit"
            :alreadySubmittedFinalOnline="
              dynamicFormProps.alreadySubmittedFinalOnline
            "
            :globalId="dynamicFormProps.globalId"
            :objectId="dynamicFormProps.objectId"
            :selectedMapServiceId="dynamicFormProps.selectedMapServiceId"
            :taskId="dynamicFormProps.taskId"
            @ticket-edit-form-close-button-click="showEditFormDialog = false"
            @ticket-edit-form-close="showEditFormDialog = false"
            @ticket-edit-form-submitted="showEditFormDialog = false"
            v-if="showEditFormDialog"
          />
        </v-card>
      </v-dialog>

      <AuthExpiredDialog
        v-if="showAuthExpiredDialog"
        :showAuthExpiredDialog="showAuthExpiredDialog"
        @portal-login-success="showAuthExpiredDialog = false"
        @auth-expired-dialog-close="showAuthExpiredDialog = false"
      />

      <MapViewActionItemsView class="py-5" :topBarHeight="topBarHeight" />
    </v-main>
  </v-app>
</template>

<script>
import TopBar from "@/components/app/TopBar.vue";
import NotificationsPane from "@/components/shared/NotificationsPane.vue";
import MapViewActionItemsView from "@/components/mapView/MapViewActionItemsView";
import usetifulMixin from "@/mixins/usetifulMixin";
import DynamicForm from "@/components/tickets/ticket-edit-form/DynamicForm";
import { mapGetters, mapMutations } from "vuex";
import fullScreenCheckMixin from "@/mixins/fullScreenCheckMixin";
import getFormResultMixin from "@/mixins/getFormResultMixin";
import AuthExpiredDialog from "@/components/app/AuthExpiredDialog";
import validateEsriTokenMixin from "@/mixins/validateEsriTokenMixin";
import notificationPaneMixin from "@/mixins/notificationPaneMixin";

export default {
  name: "ActionItems",
  components: {
    TopBar,
    NotificationsPane,
    MapViewActionItemsView,
    DynamicForm,
    AuthExpiredDialog,
  },
  mixins: [
    usetifulMixin,
    fullScreenCheckMixin,
    getFormResultMixin,
    validateEsriTokenMixin,
    notificationPaneMixin,
  ],
  data() {
    return {
      showNotificationPane: false,
      topBarHeight: 0,
      showEditFormDialog: false,
    };
  },
  methods: {
    ...mapMutations(["setDynamicFormProps"]),
    onResize() {
      this.topBarHeight = this.$refs.topBar?.$el?.clientHeight ?? 0;
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    const [elHtml] = document.getElementsByTagName("html");
    elHtml.style.overflowY = "auto";
  },
  beforeMount() {
    this.checkEsriTokenExpirationPeriodically();
  },
  async mounted() {
    const [elHtml] = document.getElementsByTagName("html");
    elHtml.style.overflowY = "hidden";
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  watch: {
    dynamicFormProps: {
      deep: true,
      async handler(val) {
        this.showEditFormDialog = false;
        await this.$nextTick();
        if (
          val?.formDefinition?.form &&
          val?.objectId &&
          val?.selectedMapServiceId
        ) {
          this.showEditFormDialog = true;
        }
      },
    },
  },
  computed: {
    ...mapGetters(["dynamicFormProps"]),
  },
};
</script>
